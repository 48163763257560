import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useReducer, useRef, useState } from "react"
import styled from "styled-components"

import { EnterOutlined, RightOutlined } from "@ant-design/icons"

import commands from "./commands"

// ----------------------------------------------------------------------------

const P_CHAR_KEYBOARD_KEY = 80
const ESC_KEYBAORD_KEY = 27

// ----------------------------------------------------------------------------

function CommandLine(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [
        {
            selectedCommand,
            currentCommandInputIndex,
            inputValue,
            isInsertCompleted,
            userInputsValues,
        },
        dispatch,
    ] = useReducer(reducer, {})

    const [isVisible, setIsVisible] = useState(false)

    const inputRef = useRef()

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        // window listener remains only one even if this statement is excuted on every isVisible change
        window.addEventListener("keydown", handleKeyDownEvent)

        if (isVisible) {
            inputRef && inputRef.current && inputRef.current.focus()
        }

        return function cleanup() {
            window.removeEventListener("keydown", handleKeyDownEvent)
        }

        //eslint-disable-next-line
    }, [isVisible])

    useEffect(() => {
        if (isInsertCompleted) {
            console.log("INSERIMENTO COMPLETATO: ", selectedCommand)
        }

        //eslint-disable-next-line
    }, [isInsertCompleted])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function reducer(state, action) {
        switch (action.type) {
            case "COMMAND_TYPE_SELECTED": {
                return {
                    ...state,
                    selectedCommand: action.payload,
                    currentCommandInputIndex: 0,
                    inputValue: "",
                    userInputsValues: {},
                }
            }

            case "USER_INPUT_VALUE_CHANGE": {
                return {
                    ...state,

                    inputValue: action.payload,
                }
            }

            case "COMMAND_INPUT_INSERTED": {
                const userInputsValues = {
                    ...state.userInputsValues,
                    [state.selectedCommand.inputs[
                        state.currentCommandInputIndex
                    ].key]: state.inputValue,
                }

                return {
                    ...state,
                    userInputsValues: userInputsValues,
                    inputValue: "",
                    currentCommandInputIndex:
                        state.currentCommandInputIndex + 1,
                }
            }

            case "EMPTY_STATE": {
                return {}
            }

            default: {
                return state
            }
        }
    }

    // -------------------------------------

    async function executeCommandFunction() {
        try {
            await selectedCommand.execute({ ...userInputsValues })

            message.success("Amministratore creato con successo")
        } catch (error) {
            message.error(
                "Non è stato possibile eseguire il comando. Contattare l'assistenza."
            )
            console.error({ error })
        }
    }

    function getControlKeyboardKey() {
        return /Mac|iPod|iPhone|iPad/.test(window.navigator.platform)
            ? "metaKey"
            : "ctrlKey"
    }

    function handleKeyDownEvent(event) {
        const controlKey = getControlKeyboardKey()

        if (
            event[controlKey] === true &&
            event.shiftKey === true &&
            event.keyCode === P_CHAR_KEYBOARD_KEY &&
            !isVisible
        ) {
            return setIsVisible(true)
        }

        if (event.keyCode === ESC_KEYBAORD_KEY && isVisible) {
            return setIsVisible(false)
        }
    }

    function findRequestedCommand(userInput) {
        return (
            commands &&
            commands.find((item) => item && item.command === userInput)
        )
    }

    function handleFormSubmit(event) {
        event.preventDefault()

        if (!selectedCommand || !selectedCommand.command) {
            const inputValue =
                inputRef && inputRef.current && inputRef.current.value
            const command = findRequestedCommand(inputValue)

            if (!command) {
                return message.error("Comando non riconosciuto")
            }

            dispatch({
                type: "COMMAND_TYPE_SELECTED",
                payload: command,
            })
        } else {
            dispatch({
                type: "COMMAND_INPUT_INSERTED",
            })
        }
    }

    function handleInputValuechange(event) {
        dispatch({
            type: "USER_INPUT_VALUE_CHANGE",
            payload: event.target.value,
        })
    }

    function renderCommandInputLabel() {
        const labelText =
            selectedCommand &&
            selectedCommand.inputs &&
            selectedCommand.inputs.length > 0 &&
            selectedCommand.inputs[currentCommandInputIndex].hint

        return <label className="hint">{labelText}</label>
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const iconStyle = {
        fontSize: 18,
        color: "#FFFFFF",
    }

    if (
        selectedCommand &&
        selectedCommand.inputs &&
        selectedCommand.inputs.length === currentCommandInputIndex
    ) {
        executeCommandFunction()

        dispatch({
            type: "EMPTY_STATE",
        })

        setIsVisible(false)
    }

    return (
        <div className={`${props.className}${isVisible ? " visible" : ""}`}>
            <div className="mask" />

            <div className="content-container">
                <div className="command-palette-input">
                    <RightOutlined style={iconStyle} />

                    {selectedCommand &&
                        selectedCommand.inputs &&
                        currentCommandInputIndex <
                            selectedCommand.inputs.length &&
                        renderCommandInputLabel()}

                    <form onSubmit={handleFormSubmit}>
                        <input
                            ref={inputRef}
                            value={inputValue}
                            onChange={handleInputValuechange}
                        />
                    </form>
                </div>

                <span className="absolute-text">
                    Premi invio (<EnterOutlined style={{ fontSize: 8 }} />) per
                    proseguire
                </span>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CommandLine.propTypes = {
    className: PropTypes.string.isRequired,
}

CommandLine.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCommandLine = styled(CommandLine)`
    & {
        position: fixed;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        min-width: 100vw;
        min-height: 100vh;

        overflow-x: hidden;
        overflow-y: auto;

        transform: scale(0);
        transform-origin: top center;

        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        z-index: 998;

        .content-container {
            position: relative;

            margin-top: 100px;
            z-index: 1000;

            .command-palette-input {
                display: flex;
                flex-direction: row;
                align-items: center;

                padding: 0 20px;

                background-color: rgba(37, 37, 37, 0.6);

                width: 500px;
                height: 60px;

                .hint {
                    margin-left: 5px;

                    font-size: 18px;
                    color: #ffffff;
                    font-weight: 500px;
                }

                > form {
                    flex: 1;
                    margin-left: 5px;

                    > input {
                        width: 100%;
                        height: 100%;

                        background-color: transparent;
                        border: none;
                        outline: none;

                        font-size: 18px;
                        color: #ffffff;
                        font-weight: 500px;
                    }
                }
            }

            .absolute-text {
                position: absolute;
                bottom: 0;
                left: 20px;

                font-size: 10px;

                color: #ffffff;
            }
        }

        .mask {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 999;
            opacity: 0;
            transition: opacity 0.1s ease 0.2s;
        }
    }

    &.visible {
        transform: scale(1);
        opacity: 1;

        .mask {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.2);
            filter: blur(48.8667px);
        }

        /* MOBILE */

        @media (max-width: ${(props) => props.theme?.screenSizes?.mobile}) {
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledCommandLine
