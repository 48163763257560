import React, { useEffect } from "react"
import styled from "styled-components"
import Lottie from "react-lottie"
import { useParams } from 'react-router-dom';


import * as api from "../../../api/Local"

import useQuery from "../../_shared/hooks/useQuery"

import * as loader from "../PaypalPayment/loading.json"

// ----------------------------------------------------------------------------

function ScalapayPayment(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const query = useQuery()
  const orderToken = query.get("orderToken")


  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const { status } = useParams()

  console.log("ROUTE STATUS: ", status)


  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (status === "success" && orderToken) {
      bridgeScalapayResponse(orderToken)
    }

  }, [status, orderToken])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function bridgeScalapayResponse(orderId) {
    try {

      const bridgePayload = {
        scalapayOrderId: orderId
      }

      window?.ReactNativeWebView?.postMessage?.(JSON.stringify(bridgePayload))
    } catch (error) { }
  }



  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const lottietOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div className={props.className}>
      <Lottie options={lottietOptions} height={100} width={100} />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------

const StyledScalapayPayment = styled(ScalapayPayment)`
  & {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: "red";
    
    > div {
      width: 100vw;
      max-width: 500px;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledScalapayPayment
