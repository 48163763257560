import { Base64 as base64 } from "js-base64"
import URLJoin from "url-join"

// ----------------------------------------------------------------------------

const DEBUG_API = process.env.NODE_ENV === "development"
const ACCESS_TOKEN = "eJkKvL9zXAlKFvIkIWEJn45i6hnkoHsE"

const DEFAULT_API_PORT = "3001"
export const BASE_URL = getBaseURL()

// ----------------------------------------------------------------------------

function getBaseURL() {
  if (process.env.NODE_ENV === "development") {
    return `http://localhost:${DEFAULT_API_PORT}`
    //return `https://cms-app.isoladeitesori.it/api`
  } else {
    return `https://cms-app.isoladeitesori.it/api`
  }
}

// ----------------------------------------------------------------------------

export class APINotOkError extends Error {
  constructor(statusCode, serverResponse) {
    const message =
      (serverResponse && serverResponse.message) ||
      `HTTP status code: ${statusCode}`
    super(message)
    this.name = "APINotOkError"
    this.statusCode = statusCode
    this.serverMessage = serverResponse && serverResponse.state
    this.errorKey = serverResponse && serverResponse.key
  }
}

// ----------------------------------------------------------------------------
// POS
// ----------------------------------------------------------------------------
export function cancelPosCall() {
  return executePOSTRequest(
    URLJoin(BASE_URL, "/pos/payments/cancel"),
    null,
    null
  )
}
// ----------------------------------------------------------------------------
// Auth
// ----------------------------------------------------------------------------

export function signUp({ ...userBody }) {
  const body = {
    ...userBody,
    access_token: ACCESS_TOKEN,
  }

  return executePOSTRequest(URLJoin(BASE_URL, "/users"), null, body)
}

export function signIn(email, password) {
  var encoded = base64.encode(`${email}:${password}`)
  const headers = {
    Authorization: `Basic ${encoded}`,
  }

  const body = { access_token: ACCESS_TOKEN }

  return executePOSTRequest(URLJoin(BASE_URL, "/auth"), null, body, headers)
}

// ----------------------------------------------------------------------------
// Users
// ----------------------------------------------------------------------------

export function createAdminUser({ email, password, name, surname }) {
  const body = {
    email,
    password,
    name,
    surname,
    role: "admin",
  }

  return signUp(body)
}

export function getUser(userId, token) {
  let url = `users/${userId}`

  return executeGETRequest(url, token)
}

export function updateUser(userId, body, token) {
  const relativeUrl = `/users/${userId}`

  const url = URLJoin(BASE_URL, relativeUrl)

  return executePUTRequest(url, token, body)
}

// ----------------------------------------------------------------------------
// SETTINGS
// ----------------------------------------------------------------------------
export function createSettings(values, token) {
  const relativeUrl = "/settings"
  const url = URLJoin(getBaseURL(), relativeUrl)

  return executePOSTRequest(url, token, values)
}
export function updateSettings(values, token) {
  const relativeUrl = "/settings"
  const url = URLJoin(getBaseURL(), relativeUrl)

  return executePUTRequest(url, token, values)
}
export function getSettings(token) {
  const relativeUrl = "/settings"

  const url = URLJoin(getBaseURL(), relativeUrl)

  return executeGETRequest(url, token)
}

// ----------------------------------------------------------------------------
// Payments
// ----------------------------------------------------------------------------

export async function createPayPalOrder(amount) {
  const relativeURL = `/payments/paypal`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  const headers = {
    "Content-Type": "application/json",
  }

  const body = {
    amount: amount,
  }
  window?.ReactNativeWebview?.postMessage?.(
    "url: " + fullURL + " -- body: " + body.amount
  )
  return executePOSTRequest(fullURL, null, body, headers)
}

export async function capturePayPalOrder(orderId) {
  const relativeURL = `/payments/paypal/${orderId}/capture`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  const headers = {
    "Content-Type": "application/json",
  }

  return executePOSTRequest(fullURL, null, {}, headers)
}

export async function getPayPalOrder(orderId) {
  const relativeURL = `/payments/paypal/${orderId}`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  const headers = {
    "Content-Type": "application/json",
  }

  return executeGETRequest(fullURL, null, {}, headers)
}

export async function createScalaPayOrder(body) {
  const relativeURL = `/payments/scalapay`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  const headers = {
    "Content-Type": "application/json",
  }

  return executePOSTRequest(fullURL, null, body, headers)
}

export async function captureScalaPayOrder(orderId) {
  const relativeURL = `/payments/scalapay/${orderId}/capture`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  const headers = {
    "Content-Type": "application/json",
  }

  return executePOSTRequest(fullURL, null, {}, headers)
}

export async function getScalaPayOrder(orderId) {
  const relativeURL = `/payments/scalapay/${orderId}`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  const headers = {
    "Content-Type": "application/json",
  }

  return executeGETRequest(fullURL, null, {}, headers)
}

// ----------------------------------------------------------------------------
// Notifications
// ----------------------------------------------------------------------------

export async function createNotification(body, token) {
  const relativeURL = `/notifications`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  return executePOSTRequest(fullURL, token, body)
}

export async function updateNotification(notificationId, body, token) {
  const relativeURL = `/notifications/${notificationId}`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  return executePUTRequest(fullURL, token, body)
}

export async function deleteNotification(notificationId, token) {
  const relativeURL = `/notifications/${notificationId}`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  return executeDELETERequest(fullURL, token)
}

export async function publishNotification(notificationId, token) {
  const relativeURL = `/notifications/${notificationId}/publish`

  const fullURL = URLJoin(getBaseURL(), relativeURL)

  return executePOSTRequest(fullURL, token)
}


// ----------------------------------------------------------------------------
// Account delete requests
// ----------------------------------------------------------------------------

export function whitelistAccount(email, token) {
  const url = URLJoin(getBaseURL(), "account-delete-requests", email)
  return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Stores
// ----------------------------------------------------------------------------
export async function importFromCsv(file, token) {
  const relativeUrl = `/stores/import`

  const url = URLJoin(BASE_URL, relativeUrl)

  const body = {
    file,
  }
  return executeFormDataRequest(url, "POST", null, body, token)
}
// ----------------------------------------------------------------------------
// Uploads
// ----------------------------------------------------------------------------

export async function uploadFile(file, token, path = "", isGif = false) {
  const relativeUrl = `/uploads`

  const url = URLJoin(BASE_URL, relativeUrl)

  const body = {
    file,
    path,
    isGif,
  }
  return executeFormDataRequest(url, "POST", null, body, token)
}

export async function deleteRemoteFile(fileUrl, token) {
  const relativeUrl = `/uploads?url=${fileUrl}`

  const url = URLJoin(BASE_URL, relativeUrl)

  return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// REST Resources
// ----------------------------------------------------------------------------

export function getResourceList(resourceURI, token, options, user) {
  const { pageNumber, pageSize = 10, sort, filters } = options
  const url = URLJoin(BASE_URL, resourceURI)

  const filterParams = {}
  for (let filterKey in filters) {
    if (filters[filterKey].length) {
      if (Array.isArray(filters[filterKey])) {
        filterParams[filterKey] = filters[filterKey].join(",")
      } else {
        filterParams[filterKey] = filters[filterKey]
      }
    }
  }

  const params = {
    ...filterParams,
    limit: pageSize,
  }

  if (pageNumber) {
    params.page = pageNumber
  }

  if (sort) {
    const sortOperator = sort.order === "descend" ? "-" : "+"
    params.sort = `${sortOperator}${sort.key}`
  }

  const urlWithQuery = urlWithQueryParams(url, params)
  return executeGETRequest(
    urlWithQuery,
    token,
    { isList: true },
    user ? { User: `${user.id}` } : null
  )
}

export async function getResource(resourceURI, token, user) {
  const url = URLJoin(BASE_URL, resourceURI)

  return executeGETRequest(
    url,
    token,
    { isList: false },
    user ? { User: `${user.id}` } : null
  )
}

export function createResource(resourceURI, token, resource) {
  const url = URLJoin(BASE_URL, resourceURI)
  return executePOSTRequest(url, token, resource)
}

export function updateResource(resourceURI, token, resourceID, resource) {
  const url = URLJoin(BASE_URL, resourceURI, resourceID)

  return executePUTRequest(url, token, resource)
}

export function deleteResource(resourceURI, token, resourceID) {
  const url = URLJoin(BASE_URL, resourceURI, resourceID)
  return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Private helpers
// ----------------------------------------------------------------------------

function executeGETRequest(url, token, options, extraHeaders) {
  const headers = {
    Authorization: token?.includes("apiKey") ? token : `Bearer ${token}`,
    ...extraHeaders,
  }

  return executeRequest(url, "get", null, headers, options)
}

function executePOSTRequest(url, token, body, headers) {
  const newHeaders = headers || {}
  if (token) {
    newHeaders["Authorization"] = token.includes("apiKey")
      ? token
      : `Bearer ${token}`
  }
  return executeRequest(url, "post", body, newHeaders)
}

function executePUTRequest(url, token, body, headers) {
  console.log("put called with p")
  const newHeaders = headers || {}
  if (token) {
    newHeaders["Authorization"] = token.includes("apiKey")
      ? token
      : `Bearer ${token}`
  }
  return executeRequest(url, "put", body, newHeaders)
}

function executeDELETERequest(url, token, headers) {
  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
  }
  return executeRequest(url, "delete", null, headers || defaultHeaders)
}

// ----------------------------------------------------------------------------

async function executeFormDataRequest(givenUrl, method, headers, body, token) {
  let url = normalizeURL(givenUrl)

  const formData = new FormData()

  // format json body into form data
  body &&
    Object.keys(body).forEach((key) => {
      if (Array.isArray(body[key])) {
        const array = body[key]

        array.forEach((data, index) => {
          const formDataArrayKey = `${key}`
          formData.append(formDataArrayKey, data)
        })
      } else {
        formData.append(key, body[key])
      }
    })

  const requestData = {
    method,
    headers: new Headers({
      ...headers,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    }),

    body: formData,
  }

  try {
    const response = await fetch(url, requestData)

    let responseBody = {}

    if (response && response.ok) {
      const responseBodyText = await response.text()

      try {
        responseBody = JSON.parse(responseBodyText)
      } catch (e) {
        responseBody = responseBodyText
      }

      return responseBody
    } else {
      throw new APINotOkError(response.status, responseBody)
    }
  } catch (error) {
    throw new Error(error)
  }
}

function executeRequest(givenURL, method, givenBody, givenHeaders, options) {
  let url = normalizeURL(givenURL)
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...givenHeaders,
  }
  let body
  if (givenBody) {
    body = JSON.stringify(givenBody)
    if (DEBUG_API) {
      // eslint-disable-next-line
      console.debug(`[API/REQUEST/BODY]`, url, givenBody)
    }
  }
  const requestData = {
    method,
    headers,
    body,
  }
  if (DEBUG_API) {
    // eslint-disable-next-line
    console.debug(`[API/REQUEST]`, url, requestData)
  }

  return fetch(url, requestData).then((response) => {
    if (DEBUG_API) {
      // eslint-disable-next-line
      console.debug(`[API/RESPONSE]`, url, response)
    }
    return response.text().then(function (text) {
      let responseBody
      try {
        responseBody = JSON.parse(text)
      } catch (e) {
        responseBody = text
      }

      if (DEBUG_API) {
        // eslint-disable-next-line
        console.debug(`[API/RESPONSE/BODY]`, responseBody)
      }

      if (response.ok) {
        if (options && options.isList) {
          return {
            data: responseBody,
          }
        } else {
          return responseBody
        }
      } else {
        throw new APINotOkError(response.status, responseBody)
      }
    })
  })
}

// -------------------------------------

function normalizeURL(givenURL) {
  let isPathAbsolute =
    givenURL.indexOf("http://") === 0 || givenURL.indexOf("https://") === 0
  if (isPathAbsolute) {
    return givenURL
  } else {
    let baseURL = `${window.location.protocol}//${window.location.hostname}:${DEFAULT_API_PORT}`

    return URLJoin(baseURL, givenURL.replace(/^\.\//, ""))
  }
}

function urlWithQueryParams(url, params) {
  const urlParams = Object.entries(params)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join("&")
  return `${url}?${urlParams}`
}

// ----------------------------------------------------------------------------
