import PropTypes from "prop-types"
import React from "react"
import { Route, Routes } from "react-router-dom"

import Layout from "../../_shared/components/Layout"
import { useStateValue } from "../../_shared/context/AppStateStore"

import Settings from "./Settings"
import Volantini from "./Volantini"
import Coupons from "./Coupon"
import Stores from "./Stores"
import Notifications from "./Notifications"
import Admins from "./Admins"

// ----------------------------------------------------------------------------

function LoggedApp(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [{ token }] = useStateValue()

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <Layout>
      <Routes>
        <Route path="/volantini" element={<Volantini />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/stores" element={<Stores />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/admins" element={<Admins />} />
      </Routes>
    </Layout>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

LoggedApp.propTypes = {
  className: PropTypes.string.isRequired,
}

LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------

export default LoggedApp
