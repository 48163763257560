import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ReactQuill from "react-quill"

import "react-quill/dist/quill.snow.css"

//-----------------------------------------------------------------------------

function WYSIWYGEditor(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { placeholder, value, ...editorProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleEditorStateChange(arg) {
    console.log("CHANGE: ", arg)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <ReactQuill
        placeholder={placeholder}
        theme="snow"
        value={value}
        onChange={handleEditorStateChange}
        {...editorProps}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

WYSIWYGEditor.propTypes = {
  className: PropTypes.string.isRequired,
  config: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.any,
}

WYSIWYGEditor.defaultProps = {
  config: {},
}

// ----------------------------------------------------------------------------

const StyledWYSIWYGEditor = styled(WYSIWYGEditor)`
  & {
    border-color: ${(props) => props.theme.colors.borderColor};

    /* override border on all editor elements */
    * {
      border-color: ${(props) => props.theme.colors.borderColor} !important;
    }

    .quill {
      .ql-toolbar.ql-snow {
        display: flex;
        flex-direction: row;
        align-items: center;

        border-radius: 10px 10px 0 0 !important;

        height: 50px;

        background-color: ${(props) =>
          props.theme.colors.primaryBackgroundColor};
      }

      .ql-container {
        border-radius: 0 0 4px 4px !important;

        min-height: 200px;
      }

      .ql-editor {
        font-size: 14px;
        color: ${(props) => props.theme.colors.textPrimary};
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledWYSIWYGEditor
