import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message } from "antd"

import * as api from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import Table from "../../../_shared/components/Table"
import EditButton from "../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

import StyledPopconfirm from "../../../_shared/components/Popconfirm"

import AdminFormBottomSheet from "./AdminFormBottomSheet"

// ----------------------------------------------------------------------------

function Admins(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [{ token }] = useStateValue()

  const [
    admins,
    fetchAdmins,
    loading,
    pagination,
    totalRecordsNumber,
    onFiltersChange,
  ] = usePaginatedRestResource("/users", token)
  const [selectedAdmin, setSelectedAdmin] = useState(null)

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleCreateAdmin(admin) {
    try {
      await api.createAdminUser(admin)
      setSelectedAdmin(null)
      fetchAdmins()
      message.success("Admin creato con successo")
    } catch (error) {
      console.error(error)
      message.error("Si è verificato un errore. Riprovare più tardi")
    }
  }

  async function handleUpdateAdmin(admin, id) {
    try {
      await api.updateResource(`/users`, token, id, admin)
      setSelectedAdmin(null)
      fetchAdmins()
      message.success("Admin aggiornato con successo")
    } catch (error) {
      console.error(error)
      message.error("Si è verificato un errore. Riprovare più tardi")
    }
  }

  function handleAdminBottomSheetConfirm(admin) {
    if (admin.id) {
      return handleUpdateAdmin(admin, admin.id)
    } else {
      return handleCreateAdmin(admin)
    }
  }

  async function handleDeleteAdmin(admin) {
    try {
      if (!admin?.id) {
        return
      }
      await api.deleteResource("/users", token, admin.id)
      fetchAdmins()
      message.success("Admin eliminato con successo")
    } catch (error) {
      message.error("Si è verificato un errore. Riprovare più tardi")
    }
  }

  function handleAdminBottomSheetCancel() {
    setSelectedAdmin(null)
  }

  function renderActions(row) {
    return (
      <div className="actions">
        <EditButton onClick={() => setSelectedAdmin(row)} />

        <StyledPopconfirm
          onConfirm={() => handleDeleteAdmin(row)}
          placement="left"
          title="Sei sicuro di voler eliminare questo admin?"
        >
          <DeleteButton />
        </StyledPopconfirm>
      </div>
    )
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const defaultAdmin = {
    name: "",
    surname: "",
    email: "",
    password: "",
  }

  return (
    <div className={`${className}`}>
      <h1 className="page-title">Admins</h1>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <AppButton
          uxType="secondary"
          onClick={() => setSelectedAdmin(defaultAdmin)}
        >
          Nuovo Admin
        </AppButton>
      </div>

      <Table
        dataSource={admins}
        pagination={{ ...pagination, total: totalRecordsNumber }}
        onChange={(pagination) => fetchAdmins({ pagination })}
        columns={getColumns(renderActions)}
        loading={loading}
      />

      <AdminFormBottomSheet
        open={selectedAdmin ? true : false}
        admin={selectedAdmin}
        onCancel={handleAdminBottomSheetCancel}
        onConfirm={handleAdminBottomSheetConfirm}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Admins.propTypes = {
  className: PropTypes.string.isRequired,
}

Admins.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledAdmins = styled(Admins)`
  & {
    .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAdmins

function getColumns(renderActions) {
  return [
    {
      title: "Nome",
      key: "fullName",
      dataIndex: "fullName",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },

    {
      key: "actions",
      render: renderActions,
    },
  ]
}
