import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import Input from "../../../../_shared/components/FormItems/AppInput"

import useForm from "../../../../_shared/hooks/useForm"

// ----------------------------------------------------------------------------

function StoreFormBottomSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { open, admin, onConfirm, volantini, ...sheetProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const initialValues = admin ?? {}

  const [formValues, setFormValue, setFormValues, clearForm] =
    useForm(initialValues)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (open) {
      setFormValues(admin ?? {})
    } else {
      clearForm()
    }
  }, [open])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleFormValueChange(key, parent) {
    return function (value) {
      if (parent) {
        const newFormValues = {
          ...formValues,
          [parent]: { ...formValues[parent], [key]: value },
        }

        setFormValues(newFormValues)
      } else {
        setFormValue(key, value)
      }
    }
  }

  function handleConfirm() {
    onConfirm?.(formValues)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------
  return (
    <BottomSheet
      className={props.className}
      open={open}
      snapPoint={80}
      title={admin?.id ? "Modifica admin" : "Nuovo admin"}
      onConfirm={handleConfirm}
      {...sheetProps}
    >
      <Input
        className="form-control"
        label="Nome"
        value={formValues.name}
        onChange={handleFormValueChange("name")}
      />
      <Input
        className="form-control"
        label="Cognome"
        value={formValues.surname}
        onChange={handleFormValueChange("surname")}
      />
      <Input
        className="form-control"
        label="Email"
        value={formValues.email}
        onChange={handleFormValueChange("email")}
      />
      <Input
        className="form-control"
        label={!admin?.id ? "Password" : "Nuova password"}
        value={formValues.password}
        onChange={handleFormValueChange("password")}
        hiddenText
      />
    </BottomSheet>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

StoreFormBottomSheet.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  admin: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
}

StoreFormBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const StoreFormBottomSheetWithStyle = styled(StoreFormBottomSheet)`
  & {
    .sheet-content {
      max-width: 500px;

      .form-control {
        margin-bottom: 20px;
        margin-left: 20px;

        width: 500px;
        max-width: 500px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StoreFormBottomSheetWithStyle
