import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { DeleteOutlined } from "@ant-design/icons"

import usePaginatedRestResource from "../../hooks/usePaginatedRestResource"
import FloatingButton from "../FloatingButton"
import AppInput from "../FormItems/AppInput"
import AppSelect from "../FormItems/AppSelect"

// ----------------------------------------------------------------------------

function UploadedImagePreview(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { src, onDelete, onChange, values, noInput } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [imageSrc, setImageSrc] = useState()
    const [filter, setFilter] = useState("")
    const [worlds, , , , , filtersDidChange] = usePaginatedRestResource(
        "/worlds",
        null,
        { pageSize: 50 }
    )

    const worldsOptions = useMemo(() => {
        return (
            worlds?.map((world) => ({
                label: world?.title,
                value: world?.id,
            })) ?? []
        )
    }, [worlds])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useState(() => {
        const isRemote = typeof src === "string" ? true : false

        if (isRemote) {
            setImageSrc(src)
        } else {
            getUploadPreview(src)
        }
        //eslint-disable-next-line
    }, [src])

    useEffect(() => {
        filtersDidChange && filtersDidChange({ title: filter })
    }, [filter])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getUploadPreview(file) {
        if (file) {
            var reader = new FileReader()

            reader.onload = function (event) {
                setImageSrc(event?.target?.result)
            }

            reader.readAsDataURL(file)
        }
    }

    function handleDelete(event) {
        event.preventDefault()

        onDelete && onDelete(values?.key)
    }

    function handleChange(field) {
        return (value) => onChange && onChange(values?.key, field, value)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <img src={imageSrc} alt="" />

            <FloatingButton className="delete-btn" onClick={handleDelete}>
                <DeleteOutlined className="delete-icn" />
            </FloatingButton>
            {!noInput && (
                <>
                    <AppInput
                        label="Titolo"
                        className="input"
                        value={values?.title}
                        onChange={handleChange("title")}
                    />
                    <AppSelect
                        className="select"
                        placeholder="Categoria"
                        options={worldsOptions}
                        onChange={handleChange("category")}
                        value={values?.category}
                        searchValue={filter}
                        onSearch={setFilter}
                    />
                </>
            )}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

UploadedImagePreview.propTypes = {
    className: PropTypes.string.isRequired,
    src: PropTypes.string,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    values: PropTypes.any,
    noInput: PropTypes.bool,
}

UploadedImagePreview.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledUploadedImagePreview = styled(UploadedImagePreview)`
    & {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 200px;
        /* height: 150px; */

        .input {
            display: block;
            width: 100%;
            margin-top: 20px;
            input {
                width: 100%;
            }
        }

        .select {
            width: 100%;
            min-width: 200px;
            margin-top: 20px;
        }

        > img {
            width: 100%;
            height: 100px;
            object-fit: contain;
            display: block;
        }

        .delete-btn {
            position: absolute;
            top: 0;
            right: 0;

            transform: translate(50%, -50%);

            transition: all 0.2s ease-in-out;

            opacity: 0;

            .delete-icn {
                font-size: 20px;
                color: ${(props) => props?.theme?.colors?.textPrimary};
            }
        }

        :hover {
            .delete-btn {
                opacity: 1;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledUploadedImagePreview
