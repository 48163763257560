import PropTypes from "prop-types"
import React from "react"
import { AiOutlineEdit } from "react-icons/ai"
import styled from "styled-components"

import FloatingButton from "../FloatingButton"

// ----------------------------------------------------------------------------

function _EditButton(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onClick } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <FloatingButton onClick={onClick} className={`${className}`}>
            <AiOutlineEdit className="icn" />
        </FloatingButton>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_EditButton.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

_EditButton.defaultProps = {}

// ----------------------------------------------------------------------------

const EditButton = styled(_EditButton)`
    & {
        .icn {
            font-size: 25px;
        }
    }
`
// ----------------------------------------------------------------------------

export default EditButton
