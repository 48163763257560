import { Modal, message } from "antd"
import PropTypes, { func } from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Barcode from "react-barcode"
import logo from "../../_shared/images/logos.png"

// ----------------------------------------------------------------------------

function _Coupons(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------
  const [coupons, setCoupons] = useState()
  const [modalCoupon, setModalCoupon] = useState()

  function getBaseURL() {
    if (process.env.NODE_ENV === "development") {
      return `http://localhost:3001`
    } else {
      return `https://cms-app.isoladeitesori.it/api`
    }
  }

  async function getCoupons() {
    const a = await fetch(`${getBaseURL()}/coupons`)
      .then((response) => response.text())
      .then((result) => {
        return JSON.parse(result)
      })
      .catch((error) => console.error(error))
    setCoupons(a)
  }

  useEffect(() => {
    getCoupons()
  }, [])

  useEffect(() => {
    console.log("coup : ", coupons?.rows)
  }, [coupons])

  function renderCoupon(x) {
    return (
      <div
        style={{
          height: 202,
          width: "90%",
          backgroundColor: "#52AE32",
          borderColor: "green",
          borderWidth: 1,
          borderStyle: "double",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "row",
          marginBottom: 15,
          padding: 20,
          borderRadius: 14,
        }}
      >
        <div
          style={{ flexDirection: "column", display: "flex", height: "50%" }}
        >
          <text style={{ fontSize: 16, width: "50%", color: "white" }}>
            {" "}
            {x?.name}
          </text>
          <div
            style={{
              fontSize: 12,
              height: "50%",
              marginTop: 10,
              color: "white",
            }}
            dangerouslySetInnerHTML={{
              __html: x?.description ? `${x?.description}` : "",
            }}
          />
          <button
            onClick={() => setModalCoupon(x?.EAN)}
            style={{
              // fontSize: 16,
              // height: "20%",
              borderColor: "#366F3B",
              backgroundColor: "#366F3B",
              color: "white",
            }}
          >
            UTILIZZA
          </button>
        </div>
        <img src={x?.image} style={{ height: "100%", width: "40%" }}></img>
      </div>
    )
  }
  console.log("modal coupon : ", modalCoupon)

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${className}`} style={{ backgroundColor: "#366F3B" }}>
      <div className="column">
        <div className="auth-box">
          <img src={logo} style={{ width: "100%" }}></img>
          <h2 style={{ color: "white", marginTop: 30 }}>
            I coupon disponibili!
          </h2>
          {/* <div style={{ overflowY: "scroll" }}> */}
          {coupons?.rows?.map((x) => renderCoupon(x))}
          {/* </div> */}
        </div>
      </div>
      <Modal
        onCancel={() => setModalCoupon()}
        footer={null}
        open={modalCoupon !== undefined}
      >
        <div
          style={{
            // justifyContent: "center",
            // display: "flex",
            // flexDirection: "column",
            paddingTop: 50,
            textAlign: "center",
          }}
        >
          <text style={{ fontSize: 14 }}>
            Vai alla cassa e mostra questo codice a barre
          </text>
          <Barcode height={150} value={modalCoupon}></Barcode>
        </div>
      </Modal>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Coupons.propTypes = {
  className: PropTypes.string.isRequired,
}

_Coupons.defaultProps = {}

// ----------------------------------------------------------------------------

const Coupons = styled(_Coupons)`
  & {
    width: 100vw;
    /* height: 100vh; */
    display: flex;

    .column {
      flex: 1;
      display: flex;

      flex-direction: row;
      /* align-items: center; */
      justify-content: center;
    }

    .bg-primary {
      background-color: ${(props) => props.theme.colors.primary};
    }

    .auth-box {
      /* padding: 30px 0px; */
      min-height: 300px;

      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      h2 {
        margin-bottom: 30px;
      }
      .input {
        margin-bottom: 20px;
      }
      .button {
        width: 300px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default Coupons
