import { Popconfirm } from "antd"
import PropTypes from "prop-types"
import React from "react"
import { AiOutlineSend as SendButton } from "react-icons/ai"
import { FiTrash as DeleteButton, FiEdit2 as EditButton } from "react-icons/fi"
import styled from "styled-components"

import FloatingButton from "../FloatingButton"

// ----------------------------------------------------------------------------

function _TableActions(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        onEdit,
        onDelete,
        onSend,
        extraButtons,
        sending,
        devicesNumber,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {onSend && (
                <Popconfirm
                    title={`Sei sicuro di voler inviare questa notifica? La notifica arriverà a ${devicesNumber} ${
                        devicesNumber > 1 ? "dispositivi" : "dispositivo"
                    }`}
                    placement="left"
                    onConfirm={onSend}
                >
                    <FloatingButton loading={sending} className="icon-button">
                        <SendButton className="icn icn-preview" />
                    </FloatingButton>
                </Popconfirm>
            )}
            {onEdit && (
                <FloatingButton onClick={onEdit} className="icon-button">
                    <EditButton className="icn icn-edit" />
                </FloatingButton>
            )}
            {onDelete && (
                <Popconfirm
                    title="Sei sicuro di voler procedere all'eliminazione?"
                    placement="left"
                    onConfirm={onDelete}
                >
                    <FloatingButton className="icon-button">
                        <DeleteButton className="icn icn-delete" />
                    </FloatingButton>
                </Popconfirm>
            )}
            {extraButtons}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TableActions.propTypes = {
    className: PropTypes.string.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSend: PropTypes.func,
    sending: PropTypes.bool,
    extraButtons: PropTypes.arrayOf(PropTypes.element),
    devicesNumber: PropTypes.number,
}

_TableActions.defaultProps = {}

// ----------------------------------------------------------------------------

const TableActions = styled(_TableActions)`
    & {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        max-width: 200px;

        .icon-button {
            .icn {
                width: 18px;
                height: 18px;
            }
            .icn-delete {
                stroke: ${({ theme }) => theme.colors.error};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TableActions
